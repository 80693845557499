import styled from "styled-components"

const MAX_WIDTH_TWO_COLUMNS = "800px"
const MAX_WIDTH_ONE_COLUMNS = "600px"

export const GridContainer = styled.div`
  padding: 71px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 49px;
  grid-row-gap: 60px;
  align-content: space-evenly;

  @media (max-width: ${MAX_WIDTH_TWO_COLUMNS}) {
    grid-template-columns: repeat(2, 1fr);
    padding: 18px;
  }

  @media (max-width: ${MAX_WIDTH_ONE_COLUMNS}) {
    grid-template-columns: 1fr;
  }
`

export const GridItem = styled.div`
  padding: 0 18px;
  min-width: 0;
`

export const GridItemFullWidth = styled(GridItem)`
  grid-column-start: 1;
  grid-column-end: -1;
`

export const GridItemTwoColumns = styled(GridItem)`
  grid-column-end: span 2;
`

export const GridItemMiddleToRight = styled(GridItem)`
  grid-column-start: 2;
  grid-column-end: -1;

  @media (max-width: ${MAX_WIDTH_ONE_COLUMNS}) {
    grid-column-start: 1;
  }
`
