import React, { useState } from "react"
import styled from "styled-components"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import header from "../../../images/header.png"
import logo from "../../../images/progwise_logo.svg"
import { GridContainer, GridItemFullWidth } from "../Grid"
import { Link } from "gatsby"

const ImgLogo = styled.img`
  width: 100%;
  max-width: 690.6px;
  max-height: 135.45px;
`

interface LogoContainerProps {
  readonly small?: boolean
}

const LogoContainer = styled.div<LogoContainerProps>`
  margin: 0;
  max-width: 100%;
  min-height: ${props => (props.small ? "0" : "440px")};
  transition: 300ms min-height;

  background-image: url(${header});
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0px;
  z-index: 1000;

  line-height: 0;

  & ${GridContainer} {
    ${props => props.small && "padding: 0;"}
    transition: 300ms padding;
  }

  & ${ImgLogo} {
    ${props => props.small && "max-height: 50px;"}
    transition: 300ms max-height;
  }

  + * {
    ${props => props.small && "margin-top: 335px;"}
    transition: 300ms margin-top;
  }
`

interface LogoProps {
  navbarHeight?: number
}

const Logo: React.FC<LogoProps> = ({ navbarHeight }) => {
  const [isLogoOnTop, setIsLogoOnTop] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      if (navbarHeight === undefined) {
        return
      }

      setIsLogoOnTop(currPos.y <= -navbarHeight)
    },
    [setIsLogoOnTop, navbarHeight]
  )

  return (
    <LogoContainer small={isLogoOnTop}>
      <GridContainer>
        <GridItemFullWidth>
          <h1>
            <Link to="/">
              <ImgLogo src={logo} alt="progwise.net - The DevOps Factory" />
            </Link>
          </h1>
        </GridItemFullWidth>
      </GridContainer>
    </LogoContainer>
  )
}

export default Logo
