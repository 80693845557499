import styled from "styled-components"

interface H3HeaderProps {
  readonly textAlign?: "left" | "center" | "right"
}

const H3Header = styled.h3<H3HeaderProps>`
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-size: 22px;
`

export default H3Header
