import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { GridContainer, GridItemFullWidth } from "../Grid"

const CustomGridContainer = styled(GridContainer)`
  padding-top: 0;
  padding-bottom: 0;
`

const NavbarContainer = styled(GridItemFullWidth)`
  padding-top: 50px;
  padding-bottom: 25px;
  text-align: right;
  font-size: 16px;

  & ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    & li {
      display: inline;
      padding-left: 20px;
      & a {
        text-decoration: none;

        font-weight: bold;
        letter-spacing: normal;
        color: #2b2e34;

        :hover {
          color: var(--progwise-blue);
        }
      }
    }
  }
`

const Navbar = () => (
  <CustomGridContainer>
    <NavbarContainer>
      <ul>
        <li>
          <Link to="/#mission">Mission</Link>
        </li>
        <li>
          <Link to="/#meet-us">Meet Us</Link>
        </li>
        <li>
          <Link to="/#services">Services</Link>
        </li>
        <li>
          <Link to="/#team">Team</Link>
        </li>
        <li>
          <Link to="/#devops-factory">DevOps</Link>
        </li>
        <li>
          <Link to="/#stack">Stack</Link>
        </li>
        <li>
          <Link to="/#contact">Contact</Link>
        </li>
      </ul>
    </NavbarContainer>
  </CustomGridContainer>
)

export default Navbar
