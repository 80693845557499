import styled from "styled-components"

const Container = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.29);
  background-color: #ffffff;
`

export default Container
