import styled from "styled-components"

// see https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header#comment35938312_13184714
const SectionWithAnchor = styled.section`
  & :before {
    content: "";
    display: block;
    position: relative;
    width: 0;

    /* To calculate the height correctly we have to set the font-size to the
    same as h1. Otherwise the unit em would not be the same. */
    font-size: 2em;

    /* This calculates the header size (img height + margin top + margin bottom) */
    height: calc(50px + 0.67em + 0.67em);
    margin-top: calc(-50px - 0.67em - 0.67em);
  }
`

export default SectionWithAnchor
