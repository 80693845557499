import React, { useRef, useEffect, useState } from "react"
import Navbar from "./Navbar"
import Logo from "./Logo"

const Header = () => {
  const navbarRef = useRef<HTMLDivElement>(null)
  const [navbarHeight, setNavbarHeight] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    if (navbarRef.current !== null) {
      const height = navbarRef.current.scrollHeight
      setNavbarHeight(height)
    }
  }, [navbarRef, setNavbarHeight])

  return (
    <>
      <div ref={navbarRef}>
        <Navbar />
      </div>
      <Logo navbarHeight={navbarHeight} />
    </>
  )
}

export default Header
