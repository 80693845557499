import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { createGlobalStyle } from "styled-components"

import "normalize.css"

import Container from "./Container"
import Header from "./header/Header"
import Footer from "./Footer"
import H2Header from "../H2Header"
import H3Header from "../H3Header"

const GlobalStyle = createGlobalStyle`
  :root {
    --progwise-blue: #0056ae;
    --progwise-blue-rgb: 0, 86, 174;
    --progwise-green: #b8cc1a;
  }

  html {
    font-family: Open Sans, sans-serif;
    scroll-behavior: smooth;
    color: #2b2e34;
    line-height: 1.5;

    word-break: break-word;
  }

  body {
    background-image: linear-gradient(to bottom, #ddf4fb, #ffffff 1400px);
  }

  a {
    color: #2b2e34;
  }

  address {
    font-style: normal
  }
`

const Layout = ({ children }) => (
  <MDXProvider
    components={{
      h1: H2Header,
      h2: H3Header,
    }}
  >
    <GlobalStyle />
    <Container>
      <Header />
      <main>{children}</main>
      <Footer />
    </Container>
  </MDXProvider>
)

export default Layout
