import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import {
  GridContainer,
  GridItem,
  GridItemFullWidth,
  GridItemTwoColumns,
} from "./Grid"
import worldMapSvg from "../../images/world-map.svg"
import SectionWithAnchor from "../SectionWithAnchor"

const FooterGridContainer = styled(GridContainer)`
  padding-top: 169px;
  padding-bottom: 107px;

  background-image: url(${worldMapSvg}),
    linear-gradient(to bottom, #393939, #727272);
  background-position: right center, top left;
  background-repeat: no-repeat, repeat;

  color: #fff;
  font-weight: bold;

  & p,
  ul {
    line-height: 2em;
  }

  & ul {
    list-style: none;
    padding: 0;
  }

  & a {
    color: #fff;
    text-decoration: none;
  }
`

const Footer = () => {
  return (
    <footer>
      <SectionWithAnchor id="contact">
        <FooterGridContainer>
          <GridItemTwoColumns>
            <h3>Contact Us</h3>
            <address>
              <p>
                progwise.net (represented by Michael Vogt)
                <br />
                Email:{" "}
                <a href="mailto:michael.vogt@progwise.net">
                  michael.vogt@progwise.net
                </a>
                <br />
                Phone: <a href="tel:+4915176995546">+49 (0)151 76995546</a>
                <br />
                Address: Schuhhagen 1, D-17489 Greifswald
                <br />
                USt-IdNr.: DE290714174
              </p>
            </address>
          </GridItemTwoColumns>
          <GridItem>
            <h3>Quick links</h3>
            <ul>
              <li>
                <Link to="/#mission">Mission</Link>
              </li>
              <li>
                <Link to="/terms-of-service">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
          </GridItem>
          <GridItemFullWidth>© 2020. All rights reserved</GridItemFullWidth>
        </FooterGridContainer>
      </SectionWithAnchor>
    </footer>
  )
}

export default Footer
