import styled from "styled-components"

const H2Header = styled.h2`
  text-align: center;
  font-size: 58px;
  font-weight: normal;
  margin: 0;
`

export default H2Header
